.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.standard-wrapper .banner-wrapper {
  position: relative;
  box-sizing: border-box;
  height: 27.083333333333332vw;
  padding-top: 4.635416666666667vw;
  background: url(https://mktv-in-cdn.mockuai.com/16257967486388142.png) 0 0 / cover no-repeat, linear-gradient(133deg, #00092A 0%, #001554 100%);
}
.standard-wrapper .banner-wrapper > .title {
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.standard-wrapper .banner-wrapper .types-container {
  box-sizing: border-box;
  position: absolute;
  left: 12.5vw;
  bottom: -6.25vw;
  width: 75vw;
  height: 23.75vw;
  padding-top: 2.7604166666666665vw;
  padding-bottom: 3.177083333333333vw;
  border-radius: 0.8333333333333334vw;
  background-color: #f9fcff;
  box-shadow: 0px 11px 29px 0px rgba(180, 180, 180, 0.1);
}
.standard-wrapper .banner-wrapper .types-container > .title {
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333;
}
.standard-wrapper .banner-wrapper .types-container .cards {
  display: flex;
  align-items: center;
  margin-top: 1.6666666666666667vw;
  margin-left: 2.083333333333333vw;
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 22.916666666666664vw;
  height: 13.541666666666666vw;
  margin-right: 1.0416666666666665vw;
  border-radius: 0.8333333333333334vw;
  background-color: rgba(255, 255, 255, 0.44);
  box-shadow: 0px -4px 39px 0px rgba(197, 202, 220, 0.21);
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item:last-child {
  margin-right: 0;
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item .icon {
  width: 4.166666666666666vw;
  height: 4.166666666666666vw;
  margin-bottom: 0.6770833333333334vw;
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item .title {
  margin-bottom: 0.4166666666666667vw;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item .desc {
  width: 18.333333333333332vw;
  line-height: 1.0416666666666665vw;
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
  text-align: center;
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item .hover-mask {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(90deg, #FF6600 0%, #FC4440 100%);
  border-radius: 0.8333333333333334vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item .hover-mask .desc {
  width: 18.229166666666664vw;
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #FFFFFF;
  text-align: left;
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item:hover {
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
}
.standard-wrapper .banner-wrapper .types-container .cards .card-item:hover .hover-mask {
  visibility: visible;
}
.standard-wrapper .qualifications-wrapper {
  box-sizing: border-box;
  height: 44.635416666666664vw;
  padding-top: 10.052083333333334vw;
  padding-bottom: 5.208333333333334vw;
}
.standard-wrapper .qualifications-wrapper > .title {
  margin-bottom: 2.7083333333333335vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.standard-wrapper .qualifications-wrapper .content-box {
  width: 75vw;
  margin: 0 auto;
}
.standard-wrapper .qualifications-wrapper .content-box .labels {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5625vw;
}
.standard-wrapper .qualifications-wrapper .content-box .labels .label {
  box-sizing: border-box;
  height: 2.5vw;
  line-height: 2.5vw;
  margin-right: 2.083333333333333vw;
  text-align: center;
  padding: 0 1.5625vw;
  border: 1px solid #999;
  border-radius: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.standard-wrapper .qualifications-wrapper .content-box .labels .label.active {
  border: none;
  background: linear-gradient(90deg, #FF6600 0%, #FC4440 100%);
  color: #fff;
}
.standard-wrapper .qualifications-wrapper .content-box .labels .label:nth-child(1),
.standard-wrapper .qualifications-wrapper .content-box .labels .label:nth-child(3) {
  width: 10.9375vw;
  padding: 0;
}
.standard-wrapper .qualifications-wrapper .content-box .cards {
  display: flex;
  align-items: center;
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item {
  box-sizing: border-box;
  position: relative;
  flex: 1;
  height: 12.5vw;
  padding-top: 2.8125vw;
  padding-left: 2.083333333333333vw;
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item:first-child {
  border-top-left-radius: 0.8333333333333334vw;
  border-bottom-left-radius: 0.8333333333333334vw;
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item:last-child {
  border-top-right-radius: 0.8333333333333334vw;
  border-bottom-right-radius: 0.8333333333333334vw;
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item:nth-child(odd) {
  background: linear-gradient(180deg, #FCFCFC 0%, #F8F8F8 100%);
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item:nth-child(even) {
  background-color: #f8f8f8;
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item::after {
  content: '';
  position: absolute;
  bottom: -1.09375vw;
  right: 0;
  width: 6.666666666666667vw;
  height: 6.666666666666667vw;
  background-size: cover;
  background-repeat: no-repeat;
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item.subject::after {
  content: '';
  background-image: url(https://mktv-in-cdn.mockuai.com/16258011389586711.png);
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item.brand::after {
  content: '';
  background-image: url(https://mktv-in-cdn.mockuai.com/16258011389419678.png);
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item.industry::after {
  content: '';
  background-image: url(https://mktv-in-cdn.mockuai.com/16258011389515163.png);
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item .title {
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.standard-wrapper .qualifications-wrapper .content-box .cards .card-item .content {
  margin-top: 2.083333333333333vw;
  line-height: 1.0416666666666665vw;
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #666666;
}
.standard-wrapper .qualifications-wrapper .btn {
  width: 12.5vw;
  height: 3.125vw;
  line-height: 3.125vw;
  margin: 5.20833333vw auto;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #ff6600;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  text-shadow: 0px 7px 21px rgba(255, 102, 0, 0.4);
  cursor: pointer;
}
.standard-wrapper .qualifications-wrapper .btn:hover {
  font-weight: 600;
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}

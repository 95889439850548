.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-creative {
  0% {
    opacity: 0;
    transform: translate3d(0, 90px, 0);
  }
  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}
@keyframes rolling {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-208.33333333%);
  }
}
.fade-in-creative {
  animation: fade-in-creative 1s ease 0s normal both;
}
.fade-in {
  animation: fade-in 1s ease 0s 1 normal both;
}
.solution-wrapper .banner-wrapper {
  box-sizing: border-box;
  height: 35.41666666666667vw;
  padding-top: 10.625vw;
  padding-bottom: 11.197916666666668vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256273266334624.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .banner-wrapper h1.title {
  margin-bottom: 0.5729166666666666vw;
  text-align: center;
  line-height: 1.4;
  font-size: 3.125vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.solution-wrapper .banner-wrapper .desc {
  text-align: center;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.solution-wrapper .banner-wrapper .actions {
  margin-top: 3.802083333333333vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solution-wrapper .banner-wrapper .actions .btn {
  box-sizing: border-box;
  width: 11.458333333333332vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  margin-right: 2.083333333333333vw;
  border-radius: 1.5625vw;
  border: 1px solid #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.solution-wrapper .banner-wrapper .actions .btn:hover {
  border: none;
  background-color: #fff;
  color: #333;
}
.solution-wrapper .banner-wrapper .actions .btn:last-child {
  margin-right: 0;
}
.solution-wrapper .banner-wrapper .actions .btn.btn-white {
  background-color: #fff;
  color: #ff6600;
}
.solution-wrapper .banner-wrapper .actions .btn.btn-white:hover {
  background-color: #ff6600;
  color: #fff;
}
.solution-wrapper .functions-wrapper {
  box-sizing: border-box;
  height: 57.291666666666664vw;
  padding-top: 5.677083333333333vw;
  padding-bottom: 7.8125vw;
  background-color: #fff;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256498222339346.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 37.29166667vw 36.45833333vw;
}
.solution-wrapper .functions-wrapper > .title {
  text-align: center;
  margin-bottom: 0.4166666666666667vw;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.solution-wrapper .functions-wrapper > .desc {
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.solution-wrapper .functions-wrapper .content-box {
  box-sizing: border-box;
  display: flex;
  width: 75vw;
  height: 31.25vw;
  margin: 8.22916667vw auto;
  background-color: #001554;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.8333333333333334vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-core {
  background-image: url(https://mktv-in-cdn.mockuai.com/16256518357993907.png);
}
.solution-wrapper .functions-wrapper .content-box.bg-core .sub-tabs {
  width: 28.125vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-core .sub-tabs .active-bar {
  width: 9.375vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-core .sub-tabs .sub-tab-item {
  width: 9.375vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-tools {
  background-image: url(https://mktv-in-cdn.mockuai.com/16256517660396134.png);
}
.solution-wrapper .functions-wrapper .content-box.bg-tools .sub-tabs {
  width: 31.25vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-tools .sub-tabs .active-bar {
  width: 10.416666666666668vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-tools .sub-tabs .sub-tab-item {
  width: 10.416666666666668vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-aftersale {
  background-image: url(https://mktv-in-cdn.mockuai.com/16256517491307362.png);
}
.solution-wrapper .functions-wrapper .content-box.bg-aftersale .sub-tabs {
  width: 28.125vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-aftersale .sub-tabs .active-bar {
  width: 9.375vw;
}
.solution-wrapper .functions-wrapper .content-box.bg-aftersale .sub-tabs .sub-tab-item {
  width: 9.375vw;
}
.solution-wrapper .functions-wrapper .content-box .tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  border-right: 1px solid rgba(216, 216, 216, 0.2);
}
.solution-wrapper .functions-wrapper .content-box .tabs .tab-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 13.541666666666666vw;
  font-size: 1.5625vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(36, 36, 85, 0.3);
}
.solution-wrapper .functions-wrapper .content-box .tabs .tab-item.active {
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  background-color: rgba(255, 102, 0, 0.8);
  color: #fff;
}
.solution-wrapper .functions-wrapper .content-box .tabs .tab-item:first-child {
  border-top-left-radius: 0.8333333333333334vw;
}
.solution-wrapper .functions-wrapper .content-box .tabs .tab-item:last-child {
  border-bottom-left-radius: 0.8333333333333334vw;
}
.solution-wrapper .functions-wrapper .content-box .panel {
  position: relative;
  flex: 1;
}
.solution-wrapper .functions-wrapper .content-box .panel .sub-tabs {
  position: relative;
  padding: 0.10416666666666667vw;
  height: 2.8125vw;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 1.6145833333333335vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .sub-tabs .active-bar {
  position: absolute;
  height: 2.8125vw;
  border-radius: 1.6145833333333335vw;
  background: linear-gradient(90deg, #ff6600 0%, #fc4440 100%);
  transition: left 0.5s;
}
.solution-wrapper .functions-wrapper .content-box .panel .sub-tabs .sub-tabs-box {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 1.6145833333333335vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .sub-tabs .sub-tabs-box .sub-tab-item {
  line-height: 2.8125vw;
  text-align: center;
  border-radius: 1.6145833333333335vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.solution-wrapper .functions-wrapper .content-box .panel .sub-tabs .sub-tabs-box .sub-tab-item.active {
  color: #fff;
}
.solution-wrapper .functions-wrapper .content-box .panel .points .point-item {
  display: flex;
  align-items: center;
  line-height: 2;
}
.solution-wrapper .functions-wrapper .content-box .panel .points .point-item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .points .point-item .text {
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #d6d6d6;
}
.solution-wrapper .functions-wrapper .content-box .panel .more {
  width: 11.458333333333332vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff6600;
  cursor: pointer;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .info {
  flex: 1;
  position: relative;
  margin-left: 4.166666666666666vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .info .title {
  line-height: 2.8125vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .info .sub-tabs {
  margin-top: 3.4375000000000004vw;
  margin-bottom: 1.6145833333333335vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .info .more {
  position: absolute;
  left: 19.791666666666664vw;
  bottom: 1.25vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .mobile-wrapper {
  position: absolute;
  right: 0.78125vw;
  top: -6.197916666666667vw;
  width: 23.125vw;
  height: 43.4375vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256517491543627.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .mobile-wrapper .decorate-img {
  position: absolute;
  z-index: 20;
  top: -0.26041666666666663vw;
  left: -6.979166666666667vw;
  width: 21.145833333333332vw;
  height: 21.145833333333332vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .mobile-wrapper .mobile-box {
  position: absolute;
  top: 1.7187500000000002vw;
  left: 1.7708333333333333vw;
  width: 17.34375vw;
  height: 37.34375vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-mobile .mobile-wrapper .mobile-box .mobile-img {
  width: 100%;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc {
  position: absolute;
  left: 0;
  top: -3.5416666666666665vw;
  width: 65.88541666666666vw;
  height: 39.947916666666664vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16257270063608743.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc.fade-enter-active,
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc.fade-leave-active {
  transition: all 0.5s ease-in-out;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc.fade-enter,
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc.fade-leave-to {
  opacity: 0;
  transform: translateX(20%);
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc.fade-enter-to,
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc.fade-leave {
  opacity: 1;
  transform: translateX(0);
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box {
  box-sizing: border-box;
  position: absolute;
  top: 3.4895833333333335vw;
  left: 8.541666666666666vw;
  width: 46.5625vw;
  height: 29.166666666666668vw;
  padding-top: 5vw;
  padding-left: 1.9791666666666665vw;
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box.bg-logistics {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257276795427226.png);
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box.bg-cloudhouse {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257276795391950.png);
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box.bg-customer {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257276795304599.png);
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box .title {
  line-height: 2.8125vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box .sub-tabs {
  margin-top: 3.4375000000000004vw;
  margin-bottom: 1.6145833333333335vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box .sub-tabs .sub-tab-item {
  width: 9.375vw;
}
.solution-wrapper .functions-wrapper .content-box .panel .panel-pc .screen-box .more {
  position: absolute;
  top: 17.34375vw;
  left: 18.90625vw;
}
.solution-wrapper .slide-tabs {
  position: relative;
  padding: 0.10416666666666667vw;
  height: 2.8125vw;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 1.6145833333333335vw;
}
.solution-wrapper .slide-tabs .active-bar {
  position: absolute;
  height: 2.8125vw;
  border-radius: 1.6145833333333335vw;
  background: linear-gradient(90deg, #ff6600 0%, #fc4440 100%);
  transition: left 0.5s;
}
.solution-wrapper .slide-tabs .tabs-box {
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 1.6145833333333335vw;
}
.solution-wrapper .slide-tabs .tabs-box .tab-item {
  line-height: 2.8125vw;
  text-align: center;
  border-radius: 1.6145833333333335vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
}
.solution-wrapper .slide-tabs .tabs-box .tab-item.active {
  color: #fff;
}
.solution-wrapper .core-section {
  box-sizing: border-box;
  height: 64.11458333333333vw;
  padding-top: 5.677083333333333vw;
  background-color: #fff;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256498222339346.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 37.29166667vw 36.45833333vw;
}
.solution-wrapper .core-section > .title {
  margin-bottom: 0.4166666666666667vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.solution-wrapper .core-section .desc {
  margin-bottom: 5.416666666666667vw;
  text-align: center;
  line-height: 1.3020833333333335vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.solution-wrapper .core-section .name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6.770833333333333vw;
}
.solution-wrapper .core-section .name .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .core-section .name .text {
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.solution-wrapper .core-section .content-box {
  position: relative;
  box-sizing: border-box;
  width: 75vw;
  height: 31.25vw;
  padding-left: 9.375vw;
  margin: 0 auto;
  background-color: #001554;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256518357993907.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0.8333333333333334vw;
}
.solution-wrapper .core-section .content-box .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 31.25vw;
  height: 100%;
}
.solution-wrapper .core-section .content-box .info .title {
  margin-bottom: 1.1979166666666667vw;
  line-height: 2.8125vw;
  font-size: 1.6666666666666667vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.solution-wrapper .core-section .content-box .info .slide-tabs {
  width: 28.125vw;
  margin-bottom: 1.6145833333333335vw;
}
.solution-wrapper .core-section .content-box .info .slide-tabs .active-bar {
  width: 9.375vw;
}
.solution-wrapper .core-section .content-box .info .slide-tabs .tab-item {
  width: 9.375vw;
}
.solution-wrapper .core-section .content-box .info .points .point-item {
  display: flex;
  align-items: center;
  line-height: 2;
}
.solution-wrapper .core-section .content-box .info .points .point-item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .core-section .content-box .info .points .point-item .text {
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #d6d6d6;
}
.solution-wrapper .core-section .content-box .mobile-wrapper {
  position: absolute;
  right: 6.09375vw;
  top: -6.197916666666667vw;
  width: 23.125vw;
  height: 43.4375vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256517491543627.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .core-section .content-box .mobile-wrapper .decorate-img {
  position: absolute;
  z-index: 20;
  top: -0.26041666666666663vw;
  left: -6.979166666666667vw;
  width: 21.145833333333332vw;
  height: 21.145833333333332vw;
}
.solution-wrapper .core-section .content-box .mobile-wrapper .mobile-box {
  position: absolute;
  top: 1.7187500000000002vw;
  left: 1.7708333333333333vw;
  width: 17.34375vw;
  height: 37.34375vw;
}
.solution-wrapper .core-section .content-box .mobile-wrapper .mobile-box .mobile-img {
  width: 100%;
}
.solution-wrapper .tools-section {
  box-sizing: border-box;
  height: 50.67708333333333vw;
  padding-top: 0.625vw;
  background-color: #fff;
}
.solution-wrapper .tools-section .name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.8125vw;
}
.solution-wrapper .tools-section .name .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .tools-section .name .text {
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.solution-wrapper .tools-section .content-box {
  position: relative;
  box-sizing: border-box;
  height: 35.989583333333336vw;
  padding-top: 10.833333333333334vw;
  background-color: #001554;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256517660396134.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .tools-section .content-box .info {
  display: flex;
  flex-direction: column;
  width: 36.45833333333333vw;
  height: 100%;
  margin-left: 50vw;
}
.solution-wrapper .tools-section .content-box .info .title {
  margin-bottom: 1.1979166666666667vw;
  line-height: 2.8125vw;
  font-size: 1.6666666666666667vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.solution-wrapper .tools-section .content-box .info .slide-tabs {
  width: 31.25vw;
  margin-bottom: 1.6145833333333335vw;
}
.solution-wrapper .tools-section .content-box .info .slide-tabs .active-bar {
  width: 10.416666666666668vw;
}
.solution-wrapper .tools-section .content-box .info .slide-tabs .tab-item {
  width: 10.416666666666668vw;
}
.solution-wrapper .tools-section .content-box .info .points .point-item {
  display: flex;
  align-items: center;
  line-height: 2;
}
.solution-wrapper .tools-section .content-box .info .points .point-item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .tools-section .content-box .info .points .point-item .text {
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #d6d6d6;
}
.solution-wrapper .tools-section .content-box .info .more {
  display: block;
  text-decoration: none;
  margin-top: 1.5625vw;
  width: 11.458333333333332vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  background-color: #fff;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff6600;
}
.solution-wrapper .tools-section .content-box .info .more:hover {
  font-weight: 600;
}
.solution-wrapper .tools-section .content-box .mobile-wrapper {
  position: absolute;
  left: 20.78125vw;
  top: 2.1875vw;
  width: 23.125vw;
  height: 43.4375vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256517491543627.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .tools-section .content-box .mobile-wrapper .decorate-img {
  position: absolute;
  z-index: 20;
  top: -0.26041666666666663vw;
  left: -6.979166666666667vw;
  width: 21.145833333333332vw;
  height: 21.145833333333332vw;
}
.solution-wrapper .tools-section .content-box .mobile-wrapper .mobile-box {
  position: absolute;
  top: 1.7187500000000002vw;
  left: 1.7708333333333333vw;
  width: 17.34375vw;
  height: 37.34375vw;
}
.solution-wrapper .tools-section .content-box .mobile-wrapper .mobile-box .mobile-img {
  width: 100%;
}
.solution-wrapper .aftersale-section {
  box-sizing: border-box;
  height: 48.541666666666664vw;
}
.solution-wrapper .aftersale-section .name {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3.802083333333333vw;
}
.solution-wrapper .aftersale-section .name .icon {
  width: 3.0208333333333335vw;
  height: 3.0208333333333335vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .aftersale-section .name .text {
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.solution-wrapper .aftersale-section .content-box {
  box-sizing: border-box;
  position: relative;
  width: 87.44791666666667vw;
  height: 29.166666666666668vw;
  padding-top: 7.447916666666667vw;
  background-color: #001554;
  background-image: url(https://mktv-in-cdn.mockuai.com/16256517491307362.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-top-right-radius: 0.8333333333333334vw;
  border-bottom-right-radius: 0.8333333333333334vw;
}
.solution-wrapper .aftersale-section .content-box .info {
  display: flex;
  flex-direction: column;
  width: 28.645833333333332vw;
  height: 100%;
  margin-left: 12.5vw;
}
.solution-wrapper .aftersale-section .content-box .info .title {
  margin-bottom: 1.1979166666666667vw;
  line-height: 2.8125vw;
  font-size: 1.6666666666666667vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.solution-wrapper .aftersale-section .content-box .info .slide-tabs {
  width: 28.125vw;
  margin-bottom: 1.6145833333333335vw;
}
.solution-wrapper .aftersale-section .content-box .info .slide-tabs .active-bar {
  width: 9.375vw;
}
.solution-wrapper .aftersale-section .content-box .info .slide-tabs .tab-item {
  width: 9.375vw;
}
.solution-wrapper .aftersale-section .content-box .info .points .point-item {
  display: flex;
  align-items: center;
  line-height: 2;
}
.solution-wrapper .aftersale-section .content-box .info .points .point-item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .aftersale-section .content-box .info .points .point-item .text {
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #d6d6d6;
}
.solution-wrapper .aftersale-section .content-box .info .more {
  display: block;
  text-decoration: none;
  margin-top: 1.5625vw;
  width: 11.458333333333332vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  background-color: #fff;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ff6600;
}
.solution-wrapper .aftersale-section .content-box .info .more:hover {
  font-weight: 600;
}
.solution-wrapper .aftersale-section .content-box .computer-wrapper {
  position: absolute;
  top: 4.270833333333333vw;
  right: -4.6875vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16275527519182551.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 51.354166666666664vw;
  height: 31.406250000000004vw;
}
.solution-wrapper .aftersale-section .content-box .computer-wrapper .computer-box {
  position: absolute;
  left: 6.666666666666667vw;
  top: 2.9166666666666665vw;
  width: 35.885416666666664vw;
  height: 22.135416666666664vw;
}
.solution-wrapper .aftersale-section .content-box .computer-wrapper .computer-box .computer-img {
  width: 100%;
}
.solution-wrapper .empowerment-wrapper {
  box-sizing: border-box;
  height: 50.416666666666664vw;
  padding-top: 5.833333333333333vw;
  padding-bottom: 6.25vw;
  background: url(https://mktv-in-cdn.mockuai.com/16257307699141603.png) bottom right / 28.48958333vw 25.57291667vw no-repeat, linear-gradient(133deg, #00092a 0%, #001554 100%);
}
.solution-wrapper .empowerment-wrapper .content-box {
  position: relative;
  margin: 0 12.5vw;
}
.solution-wrapper .empowerment-wrapper .content-box::before,
.solution-wrapper .empowerment-wrapper .content-box::after {
  content: '';
  position: absolute;
  width: 1.7708333333333333vw;
  height: 1.875vw;
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .empowerment-wrapper .content-box::before {
  content: '';
  left: 0;
  top: -2.2916666666666665vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16257305441386723.png);
}
.solution-wrapper .empowerment-wrapper .content-box::after {
  content: '';
  right: 0;
  top: 4.583333333333333vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16275694200594491.png);
}
.solution-wrapper .empowerment-wrapper .content-box .top {
  display: flex;
  align-items: center;
  margin-bottom: 5.208333333333334vw;
}
.solution-wrapper .empowerment-wrapper .content-box .top .title {
  flex: 1;
  line-height: 1.4;
  font-size: 2.083333333333333vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.solution-wrapper .empowerment-wrapper .content-box .top .desc {
  width: 42.70833333333333vw;
  line-height: 2.083333333333333vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper {
  display: flex;
  align-items: center;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item {
  transition: all 0.35s;
  position: relative;
  width: 10.416666666666668vw;
  height: 27.083333333333332vw;
  background-color: #001554;
  border-radius: 0.8333333333333334vw;
  margin-right: 1.0416666666666665vw;
  background-size: cover;
  background-repeat: no-repeat;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item:last-child {
  margin-right: 0;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.active {
  width: 40.625vw;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.active .info {
  left: 2.083333333333333vw;
  bottom: 1.7708333333333333vw;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-prod {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311558554.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-prod.active {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311593435.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-brand {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311494748.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-brand.active {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311529186.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-live {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311674011.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-live.active {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311712795.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-operation {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311638685.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item.bg-operation.active {
  background-image: url(https://mktv-in-cdn.mockuai.com/16257351311395288.png);
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 47, 141, 0) 0%, #001554 100%);
  opacity: 0.6;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info {
  transition: all 0.35s;
  position: absolute;
  height: 3.3854166666666665vw;
  bottom: 1.7708333333333333vw;
  left: 2.7083333333333335vw;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .title {
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #fff;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points {
  display: flex;
  align-items: center;
  height: 1.5625vw;
  margin-top: 0.4166666666666667vw;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points.fade-enter-active,
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points .fade-leave-active {
  transition: opacity 1s;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points.fade-enter,
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points .fade-leave-to {
  opacity: 0;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points .point-item {
  margin-right: 2.083333333333333vw;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points .point-item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.5208333333333333vw;
}
.solution-wrapper .empowerment-wrapper .content-box .slides-wrapper .slide-item .info .points .point-item .text {
  line-height: 1.5625vw;
  font-size: 0.9375vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
}
.solution-wrapper .brands-wrapper {
  box-sizing: border-box;
  height: 44.27083333333333vw;
  padding-top: 4.635416666666667vw;
  padding-bottom: 5.208333333333334vw;
  background-color: #f8f8f8;
}
.solution-wrapper .brands-wrapper .title {
  margin-bottom: 4.739583333333333vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.solution-wrapper .brands-wrapper .logo-box {
  position: relative;
  width: 75vw;
  height: 18.75vw;
  overflow: hidden;
  margin: 0 auto;
}
.solution-wrapper .brands-wrapper .logo-box .brand-img {
  width: 100%;
  display: block;
}
.solution-wrapper .brands-wrapper .logo-box .box-container {
  animation: rolling 100s linear infinite;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  width: 75vw;
  height: 18.75vw;
}
.solution-wrapper .brands-wrapper .logo-box .box-1 {
  margin-right: 3.125vw;
}
.solution-wrapper .brands-wrapper .logo-box .box-1,
.solution-wrapper .brands-wrapper .logo-box .box-2 {
  width: 153.125vw;
  height: 18.75vw;
}
.solution-wrapper .brands-wrapper .logo-box .box-1 img,
.solution-wrapper .brands-wrapper .logo-box .box-2 img {
  height: 100%;
}
.solution-wrapper .brands-wrapper .btn {
  margin: 5.20833333vw auto 0;
  width: 12.5vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  background-color: #ff6600;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.solution-wrapper .brands-wrapper .btn:hover {
  font-weight: 600;
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}

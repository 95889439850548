.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.guide-wrapper .banner-wrapper {
  position: relative;
  box-sizing: border-box;
  height: 27.083333333333332vw;
  padding-top: 4.635416666666667vw;
  background: url(https://mktv-in-cdn.mockuai.com/16258090922573186.png) 0 0 / cover no-repeat, linear-gradient(133deg, #00092A 0%, #001554 100%);
}
.guide-wrapper .banner-wrapper > .title {
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.guide-wrapper .banner-wrapper .process-container {
  box-sizing: border-box;
  position: absolute;
  bottom: -15.572916666666666vw;
  left: 12.5vw;
  width: 75vw;
  height: 32.1875vw;
  padding-top: 2.7604166666666665vw;
  border-radius: 0.8333333333333334vw;
  border: 1px solid #fff;
  background-color: #F9FCFF;
  background-image: url(https://mktv-in-cdn.mockuai.com/16258147774839652.png);
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: 0px 11px 29px 0px rgba(180, 180, 180, 0.1);
}
.guide-wrapper .banner-wrapper .process-container > .title {
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.guide-wrapper .banner-wrapper .process-container .process-steps {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 1.4583333333333333vw;
  margin-left: 6.25vw;
  margin-right: 6.25vw;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item {
  position: relative;
  padding-bottom: 7.395833333333333vw;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item:last-child .icon-box::after {
  content: '';
  width: 0;
  height: 0;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .icon-box {
  position: relative;
  width: 5.625vw;
  height: 5.625vw;
  margin-bottom: 1.25vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0px -4px 39px 0px rgba(197, 202, 220, 0.21);
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .icon-box .icon {
  width: 3.75vw;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .icon-box::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -11.09375vw;
  transform: translateY(-50%);
  width: 9.84375vw;
  height: 1.0416666666666665vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16258142733265619.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .title {
  margin-bottom: 0.6770833333333334vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .points {
  position: absolute;
  top: 9.270833333333334vw;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .points .point-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.6770833333333334vw;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .points .point-item:last-child {
  margin-bottom: 0;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .points .point-item .icon {
  width: 1.0416666666666665vw;
  height: 0.78125vw;
  margin-right: 0.4166666666666667vw;
}
.guide-wrapper .banner-wrapper .process-container .process-steps .process-item .points .point-item .text {
  line-height: 1.0416666666666665vw;
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #333333;
  white-space: pre;
}
.guide-wrapper .banner-wrapper .process-container .btn {
  margin: 3.125vw auto 0;
  width: 12.5vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  background-color: #ff6600;
  border-radius: 1.5625vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
}
.guide-wrapper .banner-wrapper .process-container .btn:hover {
  font-weight: 600;
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}
.guide-wrapper .normal-problems {
  box-sizing: border-box;
  height: 59.21875vw;
  padding-top: 19.427083333333332vw;
}
.guide-wrapper .normal-problems > .title {
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.guide-wrapper .normal-problems .swiper {
  position: relative;
  margin: 1.77083333vw 12.5vw 0;
}
.guide-wrapper .normal-problems .swiper .swiper-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2.2395833333333335vw;
  height: 2.2395833333333335vw;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}
.guide-wrapper .normal-problems .swiper .swiper-arrow.prev {
  left: -2.7604166666666665vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252291642899934.png);
}
.guide-wrapper .normal-problems .swiper .swiper-arrow.next {
  right: -2.7604166666666665vw;
  background-image: url(https://mktv-in-cdn.mockuai.com/16252259933873886.png);
}
.guide-wrapper .normal-problems .swiper .card-box {
  position: relative;
  box-sizing: border-box;
  width: 23.958333333333336vw;
  height: 30.208333333333332vw;
  padding: 2.91666667vw 1.5625vw 3.02083333vw;
  border-radius: 0.8333333333333334vw;
  background-color: #f8f8f8;
  background-size: 10.41666667vw 10.41666667vw;
  background-position: bottom -3.28125vw right 0.98958333vw;
  background-repeat: no-repeat;
  overflow: hidden;
}
.guide-wrapper .normal-problems .swiper .card-box .title {
  margin-bottom: 2.3958333333333335vw;
  line-height: 2.083333333333333vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.guide-wrapper .normal-problems .swiper .card-box .content .text {
  max-width: 20.833333333333336vw;
  margin-bottom: 1.7708333333333333vw;
  line-height: 1.25vw;
  font-size: 0.7291666666666666vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.guide-wrapper .normal-problems .swiper .swiper-slide:nth-child(1) .card-box {
  background-image: url(https://mktv-in-cdn.mockuai.com/16258198744005602.png);
}
.guide-wrapper .normal-problems .swiper .swiper-slide:nth-child(2) .card-box {
  background-image: url(https://mktv-in-cdn.mockuai.com/16258198743967756.png);
}
.guide-wrapper .normal-problems .swiper .swiper-slide:nth-child(3) .card-box {
  background-image: url(https://mktv-in-cdn.mockuai.com/16258198743934024.png);
}
.guide-wrapper .normal-problems .swiper .swiper-slide:nth-child(4) .card-box {
  background-image: url(https://mktv-in-cdn.mockuai.com/16258198743903479.png);
}
.guide-wrapper .normal-problems .swiper .swiper-slide:nth-child(5) .card-box {
  background-image: url(https://mktv-in-cdn.mockuai.com/16258198743809032.png);
}
